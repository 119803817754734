<template>
  <div v-if="selectedPropertyOwnershipType !== 1 && officialPartnerAreas.length > 0">
    <div class="form--group">
      <div class="left--col">
        <label for="offical-partner-area-field">
          <span>{{ $t('addListing.officialPartnerArea') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <multiselect
          name="offical_partner_area"
          v-model="selectedOfficialPartnerArea"
          :options="officialPartnerAreas"
          :close-on-select="true"
          :placeholder="$t('addListing.placeholder.officialPartnerArea')"
          track-by="id"
          label="name"
          id="offical-partner-area-field"
        >
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters, mapState } from 'vuex';

require('vue-multiselect/dist/vue-multiselect.min.css');
export default {
  name: 'official-partner-area-section',
  components: { Multiselect },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      // officialPartnerAreas: state =>
      //   state.v2.listingForm.sect1.location.propertyOwnershipTypeList,
    }),
    ...mapGetters({
      officialPartnerAreas: 'v2/listingForm/sect1/location/allowedOfficialPartnerAreas',
      selectedPropertyOwnershipType: 'v2/listingForm/sect1/location/selectedPropertyOwnershipType',
    }),
    selectedOfficialPartnerArea: {
      get() {
        return this.$store.getters[
          'v2/listingForm/sect1/location/selectedOfficialPartnerAreaObject'
        ];
      },
      set(value) {
        return this.$store.commit(
          'v2/listingForm/sect1/location/SET_SELECTED_OFFICIAL_PARTNER_AREA',
          value ? value.uuid : null,
        );
      },
    },
  },
  methods: {},
};
</script>

<style scoped></style>
